<template>
  <v-container fluid>
    <PriceList />
  </v-container>
</template>

<script>
export default {
  name: 'TerminalGoods',

  components: {
    PriceList: () => import('@/components/common/PriceList'),
  },
}
</script>

<style scoped></style>
